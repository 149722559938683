import { HighlightOffOutlined } from '@mui/icons-material';

import * as S from '../styles';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const CustomClearIndicator = ({ innerRef, innerProps }: any) => (
  <S.IndicatorWrapper ref={innerRef} {...innerProps} className="layout-column layout-align-center-center mx-8">
    <HighlightOffOutlined className={'text-secondary-700'} style={{ width: '1rem', height: '1.5rem' }} />
  </S.IndicatorWrapper>
);

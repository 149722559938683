// TODO: Make sure colors are matching new branding
export const SHADOWS = {
  DEPTH_1: {
    PRIMARY_100: '0 1px 2px rgba(230, 250, 254, 0.075), 0 0px 15px rgba(230, 250, 254, 0.06)',
    PRIMARY_300: '0 1px 2px rgba(147, 223, 236, 0.075), 0 0px 15px rgba(147, 223, 236, 0.06)',
    PRIMARY_500: '0 1px 2px rgba(32, 197, 229, 0.075), 0 0px 15px rgba(32, 197, 229, 0.06)',
    PRIMARY_700: '0 1px 2px rgba(5, 163, 184, 0.075), 0 0px 15px rgba(5, 163, 184, 0.06)',
    PRIMARY_900: '0 1px 2px rgba(0, 90, 102, 0.075), 0 0px 15px rgba(0, 90, 102, 0.06)',
    SECONDARY_100: '0 1px 2px rgba(255, 255, 255, 0.075), 0 0px 15px rgba(255, 255, 255, 0.06)',
    SECONDARY_300: '0 1px 2px rgba(250, 250, 250, 0.075), 0 0px 15px rgba(250, 250, 250, 0.06)',
    SECONDARY_500: '0 1px 2px rgba(235, 235, 235, 0.075), 0 0px 15px rgba(235, 235, 235, 0.06)',
    SECONDARY_700: '0 1px 2px rgba(200, 200, 200, 0.075), 0 0px 15px rgba(200, 200, 200, 0.06)',
    SECONDARY_900: '0 1px 2px rgba(144, 144, 144, 0.075), 0 0px 15px rgba(144, 144, 144, 0.06)',
    SUCCESS_100: '0 1px 2px rgba(190, 242, 223, 0.075), 0 0px 15px rgba(190, 242, 223, 0.06)',
    SUCCESS_300: '0 1px 2px rgba(134, 226, 186, 0.075), 0 0px 15px rgba(134, 226, 186, 0.06)',
    SUCCESS_500: '0 1px 2px rgba(83, 227, 186, 0.075), 0 0px 15px rgba(83, 227, 186, 0.06)',
    SUCCESS_700: '0 1px 2px rgba(0, 153, 115, 0.075), 0 0px 15px rgba(0, 153, 115, 0.06)',
    SUCCESS_900: '0 1px 2px rgba(0, 102, 78, 0.075), 0 0px 15px rgba(0, 102, 78, 0.06)',
    DANGER_100: '0 1px 2px rgba(255, 229, 235, 0.075), 0 0px 15px rgba(255, 229, 235, 0.06)',
    DANGER_300: '0 1px 2px rgba(246, 120, 155, 0.075), 0 0px 15px rgba(246, 120, 155, 0.06)',
    DANGER_500: '0 1px 2px rgba(255, 57, 112, 0.075), 0 0px 15px rgba(255, 57, 112, 0.06)',
    DANGER_700: '0 1px 2px rgba(214, 52, 88, 0.075), 0 0px 15px rgba(214, 52, 88, 0.06)',
    DANGER_900: '0 1px 2px rgba(147, 0, 33, 0.075), 0 0px 15px rgba(147, 0, 33, 0.06)',
    WARNING_100: '0 1px 2px rgba(255, 234, 204, 0.075), 0 0px 15px rgba(255, 234, 204, 0.06)',
    WARNING_300: '0 1px 2px rgba(255, 215, 153, 0.075), 0 0px 15px rgba(255, 215, 153, 0.06)',
    WARNING_500: '0 1px 2px rgba(255, 192, 97, 0.075), 0 0px 15px rgba(255, 192, 97, 0.06)',
    WARNING_700: '0 1px 2px rgba(236, 143, 5, 0.075), 0 0px 15px rgba(236, 143, 5, 0.06)',
    WARNING_900: '0 1px 2px rgba(167, 101, 4, 0.075), 0 0px 15px rgba(167, 101, 4, 0.06)',
    NEUTRAL_100: '0 1px 2px rgba(27, 66, 175, 0.075), 0 0px 15px rgba(27, 66, 175, 0.06)',
    NEUTRAL_300: '0 1px 2px rgba(21, 50, 132, 0.075), 0 0px 15px rgba(21, 50, 132, 0.06)',
    NEUTRAL_500: '0 1px 2px rgba(14, 33, 88, 0.075), 0 0px 15px rgba(14, 33, 88, 0.06)',
    NEUTRAL_700: '0 1px 2px rgba(7, 17, 44, 0.075), 0 0px 15px rgba(7, 17, 44, 0.06)',
    NEUTRAL_900: '0 1px 2px rgba(0, 0, 0, 0.075), 0 0px 15px rgba(0, 0, 0, 0.06)',
  },
  DEPTH_2: {
    PRIMARY_100: '0 2px 4px rgba(230, 250, 254, 0.1), 0 0px 20px rgba(230, 250, 254, 0.08)',
    PRIMARY_300: '0 2px 4px rgba(147, 223, 236, 0.1), 0 0px 20px rgba(147, 223, 236, 0.08)',
    PRIMARY_500: '0 2px 4px rgba(32, 197, 229, 0.1), 0 0px 20px rgba(32, 197, 229, 0.08)',
    PRIMARY_700: '0 2px 4px rgba(5, 163, 184, 0.1), 0 0px 20px rgba(5, 163, 184, 0.08)',
    PRIMARY_900: '0 2px 4px rgba(0, 90, 102, 0.1), 0 0px 20px rgba(0, 90, 102, 0.08)',
    SECONDARY_100: '0 2px 4px rgba(255, 255, 255, 0.1), 0 0px 20px rgba(255, 255, 255, 0.08)',
    SECONDARY_300: '0 2px 4px rgba(250, 250, 250, 0.1), 0 0px 20px rgba(250, 250, 250, 0.08)',
    SECONDARY_500: '0 2px 4px rgba(235, 235, 235, 0.1), 0 0px 20px rgba(235, 235, 235, 0.08)',
    SECONDARY_700: '0 2px 4px rgba(200, 200, 200, 0.1), 0 0px 20px rgba(200, 200, 200, 0.08)',
    SECONDARY_900: '0 2px 4px rgba(144, 144, 144, 0.1), 0 0px 20px rgba(144, 144, 144, 0.08)',
    SUCCESS_100: '0 2px 4px rgba(190, 242, 223, 0.1), 0 0px 20px rgba(190, 242, 223, 0.08)',
    SUCCESS_300: '0 2px 4px rgba(134, 226, 186, 0.1), 0 0px 20px rgba(134, 226, 186, 0.08)',
    SUCCESS_500: '0 2px 4px rgba(83, 227, 186, 0.1), 0 0px 20px rgba(83, 227, 186, 0.08)',
    SUCCESS_700: '0 2px 4px rgba(0, 153, 115, 0.1), 0 0px 20px rgba(0, 153, 115, 0.08)',
    SUCCESS_900: '0 2px 4px rgba(0, 102, 78, 0.1), 0 0px 20px rgba(0, 102, 78, 0.08)',
    DANGER_100: '0 2px 4px rgba(255, 229, 235, 0.1), 0 0px 20px rgba(255, 229, 235, 0.08)',
    DANGER_300: '0 2px 4px rgba(246, 120, 155, 0.1), 0 0px 20px rgba(246, 120, 155, 0.08)',
    DANGER_500: '0 2px 4px rgba(255, 57, 112, 0.1), 0 0px 20px rgba(255, 57, 112, 0.08)',
    DANGER_700: '0 2px 4px rgba(214, 52, 88, 0.1), 0 0px 20px rgba(214, 52, 88, 0.08)',
    DANGER_900: '0 2px 4px rgba(147, 0, 33, 0.1), 0 0px 20px rgba(147, 0, 33, 0.08)',
    WARNING_100: '0 2px 4px rgba(255, 234, 204, 0.1), 0 0px 20px rgba(255, 234, 204, 0.08)',
    WARNING_300: '0 2px 4px rgba(255, 215, 153, 0.1), 0 0px 20px rgba(255, 215, 153, 0.08)',
    WARNING_500: '0 2px 4px rgba(255, 192, 97, 0.1), 0 0px 20px rgba(255, 192, 97, 0.08)',
    WARNING_700: '0 2px 4px rgba(236, 143, 5, 0.1), 0 0px 20px rgba(236, 143, 5, 0.08)',
    WARNING_900: '0 2px 4px rgba(167, 101, 4, 0.1), 0 0px 20px rgba(167, 101, 4, 0.08)',
    NEUTRAL_100: '0 2px 4px rgba(27, 66, 175, 0.1), 0 0px 20px rgba(27, 66, 175, 0.08)',
    NEUTRAL_300: '0 2px 4px rgba(21, 50, 132, 0.1), 0 0px 20px rgba(21, 50, 132, 0.08)',
    NEUTRAL_500: '0 2px 4px rgba(14, 33, 88, 0.1), 0 0px 20px rgba(14, 33, 88, 0.08)',
    NEUTRAL_700: '0 2px 4px rgba(7, 17, 44, 0.1), 0 0px 20px rgba(7, 17, 44, 0.08)',
    NEUTRAL_900: '0 2px 4px rgba(0, 0, 0, 0.1), 0 0px 20px rgba(0, 0, 0, 0.08)',
  },
  DEPTH_3: {
    PRIMARY_100: '0 4px 8px rgba(230, 250, 254, 0.125), 0 0px 25px rgba(230, 250, 254, 0.1)',
    PRIMARY_300: '0 4px 8px rgba(147, 223, 236, 0.125), 0 0px 25px rgba(147, 223, 236, 0.1)',
    PRIMARY_500: '0 4px 8px rgba(32, 197, 229, 0.125), 0 0px 25px rgba(32, 197, 229, 0.1)',
    PRIMARY_700: '0 4px 8px rgba(5, 163, 184, 0.125), 0 0px 25px rgba(5, 163, 184, 0.1)',
    PRIMARY_900: '0 4px 8px rgba(0, 90, 102, 0.125), 0 0px 25px rgba(0, 90, 102, 0.1)',
    SECONDARY_100: '0 4px 8px rgba(255, 255, 255, 0.125), 0 0px 25px rgba(255, 255, 255, 0.1)',
    SECONDARY_300: '0 4px 8px rgba(250, 250, 250, 0.125), 0 0px 25px rgba(250, 250, 250, 0.1)',
    SECONDARY_500: '0 4px 8px rgba(235, 235, 235, 0.125), 0 0px 25px rgba(235, 235, 235, 0.1)',
    SECONDARY_700: '0 4px 8px rgba(200, 200, 200, 0.125), 0 0px 25px rgba(200, 200, 200, 0.1)',
    SECONDARY_900: '0 4px 8px rgba(144, 144, 144, 0.125), 0 0px 25px rgba(144, 144, 144, 0.1)',
    SUCCESS_100: '0 4px 8px rgba(190, 242, 223, 0.125), 0 0px 25px rgba(190, 242, 223, 0.1)',
    SUCCESS_300: '0 4px 8px rgba(134, 226, 186, 0.125), 0 0px 25px rgba(134, 226, 186, 0.1)',
    SUCCESS_500: '0 4px 8px rgba(83, 227, 186, 0.125), 0 0px 25px rgba(83, 227, 186, 0.1)',
    SUCCESS_700: '0 4px 8px rgba(0, 153, 115, 0.125), 0 0px 25px rgba(0, 153, 115, 0.1)',
    SUCCESS_900: '0 4px 8px rgba(0, 102, 78, 0.125), 0 0px 25px rgba(0, 102, 78, 0.1)',
    DANGER_100: '0 4px 8px rgba(255, 229, 235, 0.125), 0 0px 25px rgba(255, 229, 235, 0.1)',
    DANGER_300: '0 4px 8px rgba(246, 120, 155, 0.125), 0 0px 25px rgba(246, 120, 155, 0.1)',
    DANGER_500: '0 4px 8px rgba(255, 57, 112, 0.125), 0 0px 25px rgba(255, 57, 112, 0.1)',
    DANGER_700: '0 4px 8px rgba(214, 52, 88, 0.125), 0 0px 25px rgba(214, 52, 88, 0.1)',
    DANGER_900: '0 4px 8px rgba(147, 0, 33, 0.125), 0 0px 25px rgba(147, 0, 33, 0.1)',
    WARNING_100: '0 4px 8px rgba(255, 234, 204, 0.125), 0 0px 25px rgba(255, 234, 204, 0.1)',
    WARNING_300: '0 4px 8px rgba(255, 215, 153, 0.125), 0 0px 25px rgba(255, 215, 153, 0.1)',
    WARNING_500: '0 4px 8px rgba(255, 192, 97, 0.125), 0 0px 25px rgba(255, 192, 97, 0.1)',
    WARNING_700: '0 4px 8px rgba(236, 143, 5, 0.125), 0 0px 25px rgba(236, 143, 5, 0.1)',
    WARNING_900: '0 4px 8px rgba(167, 101, 4, 0.125), 0 0px 25px rgba(167, 101, 4, 0.1)',
    NEUTRAL_100: '0 4px 8px rgba(27, 66, 175, 0.125), 0 0px 25px rgba(27, 66, 175, 0.1)',
    NEUTRAL_300: '0 4px 8px rgba(21, 50, 132, 0.125), 0 0px 25px rgba(21, 50, 132, 0.1)',
    NEUTRAL_500: '0 4px 8px rgba(14, 33, 88, 0.125), 0 0px 25px rgba(14, 33, 88, 0.1)',
    NEUTRAL_700: '0 4px 8px rgba(7, 17, 44, 0.125), 0 0px 25px rgba(7, 17, 44, 0.1)',
    NEUTRAL_900: '0 4px 8px rgba(0, 0, 0, 0.125), 0 0px 25px rgba(0, 0, 0, 0.1)',
  },
  DEPTH_4: {
    PRIMARY_100: '0 8px 16px rgba(230, 250, 254, 0.15), 0 10px 30px rgba(230, 250, 254, 0.12)',
    PRIMARY_300: '0 8px 16px rgba(147, 223, 236, 0.15), 0 10px 30px rgba(147, 223, 236, 0.12)',
    PRIMARY_500: '0 8px 16px rgba(32, 197, 229, 0.15), 0 10px 30px rgba(32, 197, 229, 0.12)',
    PRIMARY_700: '0 8px 16px rgba(5, 163, 184, 0.15), 0 10px 30px rgba(5, 163, 184, 0.12)',
    PRIMARY_900: '0 8px 16px rgba(0, 90, 102, 0.15), 0 10px 30px rgba(0, 90, 102, 0.12)',
    SECONDARY_100: '0 8px 16px rgba(255, 255, 255, 0.15), 0 10px 30px rgba(255, 255, 255, 0.12)',
    SECONDARY_300: '0 8px 16px rgba(250, 250, 250, 0.15), 0 10px 30px rgba(250, 250, 250, 0.12)',
    SECONDARY_500: '0 8px 16px rgba(235, 235, 235, 0.15), 0 10px 30px rgba(235, 235, 235, 0.12)',
    SECONDARY_700: '0 8px 16px rgba(200, 200, 200, 0.15), 0 10px 30px rgba(200, 200, 200, 0.12)',
    SECONDARY_900: '0 8px 16px rgba(144, 144, 144, 0.15), 0 10px 30px rgba(144, 144, 144, 0.12)',
    SUCCESS_100: '0 8px 16px rgba(190, 242, 223, 0.15), 0 10px 30px rgba(190, 242, 223, 0.12)',
    SUCCESS_300: '0 8px 16px rgba(134, 226, 186, 0.15), 0 10px 30px rgba(134, 226, 186, 0.12)',
    SUCCESS_500: '0 8px 16px rgba(83, 227, 186, 0.15), 0 10px 30px rgba(83, 227, 186, 0.12)',
    SUCCESS_700: '0 8px 16px rgba(0, 153, 115, 0.15), 0 10px 30px rgba(0, 153, 115, 0.12)',
    SUCCESS_900: '0 8px 16px rgba(0, 102, 78, 0.15), 0 10px 30px rgba(0, 102, 78, 0.12)',
    DANGER_100: '0 8px 16px rgba(255, 229, 235, 0.15), 0 10px 30px rgba(255, 229, 235, 0.12)',
    DANGER_300: '0 8px 16px rgba(246, 120, 155, 0.15), 0 10px 30px rgba(246, 120, 155, 0.12)',
    DANGER_500: '0 8px 16px rgba(255, 57, 112, 0.15), 0 10px 30px rgba(255, 57, 112, 0.12)',
    DANGER_700: '0 8px 16px rgba(214, 52, 88, 0.15), 0 10px 30px rgba(214, 52, 88, 0.12)',
    DANGER_900: '0 8px 16px rgba(147, 0, 33, 0.15), 0 10px 30px rgba(147, 0, 33, 0.12)',
    WARNING_100: '0 8px 16px rgba(255, 234, 204, 0.15), 0 10px 30px rgba(255, 234, 204, 0.12)',
    WARNING_300: '0 8px 16px rgba(255, 215, 153, 0.15), 0 10px 30px rgba(255, 215, 153, 0.12)',
    WARNING_500: '0 8px 16px rgba(255, 192, 97, 0.15), 0 10px 30px rgba(255, 192, 97, 0.12)',
    WARNING_700: '0 8px 16px rgba(236, 143, 5, 0.15), 0 10px 30px rgba(236, 143, 5, 0.12)',
    WARNING_900: '0 8px 16px rgba(167, 101, 4, 0.15), 0 10px 30px rgba(167, 101, 4, 0.12)',
    NEUTRAL_100: '0 8px 16px rgba(27, 66, 175, 0.15), 0 10px 30px rgba(27, 66, 175, 0.12)',
    NEUTRAL_300: '0 8px 16px rgba(21, 50, 132, 0.15), 0 10px 30px rgba(21, 50, 132, 0.12)',
    NEUTRAL_500: '0 8px 16px rgba(14, 33, 88, 0.15), 0 10px 30px rgba(14, 33, 88, 0.12)',
    NEUTRAL_700: '0 8px 16px rgba(7, 17, 44, 0.15), 0 10px 30px rgba(7, 17, 44, 0.12)',
    NEUTRAL_900: '0 8px 16px rgba(0, 0, 0, 0.15), 0 10px 30px rgba(0, 0, 0, 0.12)',
  },
  DEPTH_5: {
    PRIMARY_100: '0 16px 32px rgba(230, 250, 254, 0.175), 0 15px 35px rgba(230, 250, 254, 0.16)',
    PRIMARY_300: '0 16px 32px rgba(147, 223, 236, 0.175), 0 15px 35px rgba(147, 223, 236, 0.16)',
    PRIMARY_500: '0 16px 32px rgba(32, 197, 229, 0.175), 0 15px 35px rgba(32, 197, 229, 0.16)',
    PRIMARY_700: '0 16px 32px rgba(5, 163, 184, 0.175), 0 15px 35px rgba(5, 163, 184, 0.16)',
    PRIMARY_900: '0 16px 32px rgba(0, 90, 102, 0.175), 0 15px 35px rgba(0, 90, 102, 0.16)',
    SECONDARY_100: '0 16px 32px rgba(255, 255, 255, 0.175), 0 15px 35px rgba(255, 255, 255, 0.16)',
    SECONDARY_300: '0 16px 32px rgba(250, 250, 250, 0.175), 0 15px 35px rgba(250, 250, 250, 0.16)',
    SECONDARY_500: '0 16px 32px rgba(235, 235, 235, 0.175), 0 15px 35px rgba(235, 235, 235, 0.16)',
    SECONDARY_700: '0 16px 32px rgba(200, 200, 200, 0.175), 0 15px 35px rgba(200, 200, 200, 0.16)',
    SECONDARY_900: '0 16px 32px rgba(144, 144, 144, 0.175), 0 15px 35px rgba(144, 144, 144, 0.16)',
    SUCCESS_100: '0 16px 32px rgba(190, 242, 223, 0.175), 0 15px 35px rgba(190, 242, 223, 0.16)',
    SUCCESS_300: '0 16px 32px rgba(134, 226, 186, 0.175), 0 15px 35px rgba(134, 226, 186, 0.16)',
    SUCCESS_500: '0 16px 32px rgba(83, 227, 186, 0.175), 0 15px 35px rgba(83, 227, 186, 0.16)',
    SUCCESS_700: '0 16px 32px rgba(0, 153, 115, 0.175), 0 15px 35px rgba(0, 153, 115, 0.16)',
    SUCCESS_900: '0 16px 32px rgba(0, 102, 78, 0.175), 0 15px 35px rgba(0, 102, 78, 0.16)',
    DANGER_100: '0 16px 32px rgba(255, 229, 235, 0.175), 0 15px 35px rgba(255, 229, 235, 0.16)',
    DANGER_300: '0 16px 32px rgba(246, 120, 155, 0.175), 0 15px 35px rgba(246, 120, 155, 0.16)',
    DANGER_500: '0 16px 32px rgba(255, 57, 112, 0.175), 0 15px 35px rgba(255, 57, 112, 0.16)',
    DANGER_700: '0 16px 32px rgba(214, 52, 88, 0.175), 0 15px 35px rgba(214, 52, 88, 0.16)',
    DANGER_900: '0 16px 32px rgba(147, 0, 33, 0.175), 0 15px 35px rgba(147, 0, 33, 0.16)',
    WARNING_100: '0 16px 32px rgba(255, 234, 204, 0.175), 0 15px 35px rgba(255, 234, 204, 0.16)',
    WARNING_300: '0 16px 32px rgba(255, 215, 153, 0.175), 0 15px 35px rgba(255, 215, 153, 0.16)',
    WARNING_500: '0 16px 32px rgba(255, 192, 97, 0.175), 0 15px 35px rgba(255, 192, 97, 0.16)',
    WARNING_700: '0 16px 32px rgba(236, 143, 5, 0.175), 0 15px 35px rgba(236, 143, 5, 0.16)',
    WARNING_900: '0 16px 32px rgba(167, 101, 4, 0.175), 0 15px 35px rgba(167, 101, 4, 0.16)',
    NEUTRAL_100: '0 16px 32px rgba(27, 66, 175, 0.175), 0 15px 35px rgba(27, 66, 175, 0.16)',
    NEUTRAL_300: '0 16px 32px rgba(21, 50, 132, 0.175), 0 15px 35px rgba(21, 50, 132, 0.16)',
    NEUTRAL_500: '0 16px 32px rgba(14, 33, 88, 0.175), 0 15px 35px rgba(14, 33, 88, 0.16)',
    NEUTRAL_700: '0 16px 32px rgba(7, 17, 44, 0.175), 0 15px 35px rgba(7, 17, 44, 0.16)',
    NEUTRAL_900: '0 16px 32px rgba(0, 0, 0, 0.175), 0 15px 35px rgba(0, 0, 0, 0.16)',
  },
};

/**
 * Check if an event is on the target element. (Refs)
 */
import type { RefObject } from 'react';

export const checkFocusOnTargetRef = (targetRef: RefObject<HTMLElement>, event: MouseEvent): boolean => {
  if (!targetRef || !targetRef.current) return false;
  return targetRef.current.contains(event.target as HTMLElement);
};

/**
 * Scroll elemend inside top of his container. (Refs)
 */
export const scrollRefToTop = (parentRef: RefObject<HTMLElement>, childRef: RefObject<HTMLElement>) => {
  parentRef?.current?.scrollTo({ top: childRef?.current?.offsetTop });
};

/**
 * Transform hexa color code to rgba.
 */
export const hexToRgb = (hex: string) => {
  let hue: string[] | undefined = undefined;
  if (/^#(?:[A-Fa-f\d]{3}){1,2}$/.test(hex)) {
    hue = hex.substring(1).split('');
    if (hue.length === 3) {
      hue = [hue[0], hue[0], hue[1], hue[1], hue[2], hue[2]];
    }
    const newHue: number = parseInt('0x' + hue.join(''));
    return [(newHue >> 16) & 255, (newHue >> 8) & 255, newHue & 255].join(',');
  }
  throw new Error('There was an error with the hex color.');
};

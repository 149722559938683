/* eslint-disable sonarjs/cognitive-complexity */

import styled from '@emotion/styled';
import type { StylesConfig } from 'react-select';

import type { SelectProps } from '.';
import { COLORS, RADIUSES, SHADOWS, TYPOGRAPHY } from '../../variables';
import type { InputProps } from '../Forms/Input';
import { hexToRgb } from '../helpers';
import { FormElementIcon } from './FormElementIcon';

export type FormElementProps = {
  error?: boolean;
  disabled?: boolean;
  prepend?: boolean;
  isLeftIcon?: boolean;
  isRightIcon?: boolean;
  resize?: 'horizontal' | 'vertical' | 'both';
  fieldStyle?: InputProps['fieldStyle'];
};

export const FormContainer = styled.div`
  width: 100%;
  display: flex;
  position: relative;
  border-style: solid;
  flex-direction: row;
  border-width: 1px;
  border-radius: ${RADIUSES.sm};
  ${(props: FormElementProps) => `
    ${
      props.fieldStyle === 'select'
        ? `
          border-color: ${
            props.error
              ? COLORS.DANGER_500
              : props.disabled
                ? `rgba(${hexToRgb(COLORS.SECONDARY_500)}, 0.5)`
                : COLORS.SECONDARY_300
          };
          &:hover:not([disabled]) {
            border-color: ${
              props.error
                ? props.disabled
                  ? `rgba(${hexToRgb(COLORS.SECONDARY_500)}, 0.5)`
                  : COLORS.DANGER_500
                : COLORS.SECONDARY_700
            };
          }
          > input {
            cursor: pointer;
            caret-color: transparent;
          }
        `
        : `
        border-color: ${
          props.error
            ? COLORS.DANGER_300
            : props.disabled
              ? `rgba(${hexToRgb(COLORS.SECONDARY_300)}, 0.3)`
              : `rgba(${hexToRgb(COLORS.SECONDARY_500)}, 0.3)`
        };
        &:hover  {
          border-color: ${
            props.error
              ? COLORS.DANGER_500
              : props.disabled
                ? `rgba(${hexToRgb(COLORS.SECONDARY_300)}, 0.3)`
                : COLORS.SECONDARY_700
          };
        }
      `
    };
  `}
`;

export const FormElementBase = styled.div`
  ${(props: FormElementProps) => `
    border: none;
    outline: none;
    background-image: none;
    font-size: ${TYPOGRAPHY.P};
    color: ${props.error ? COLORS.DANGER_500 : COLORS.SECONDARY_900};
    border-color: ${props.error ? COLORS.DANGER_500 : COLORS.SECONDARY_300};
    background-color: ${
      props.error
        ? COLORS.DANGER_100
        : // eslint-disable-next-line sonarjs/no-nested-template-literals
          `${props.fieldStyle === 'input' ? `rgba(${hexToRgb(COLORS.SECONDARY_300)}, 0.3)` : COLORS.NEUTRAL_100}`
    };

    &[disabled] {
      cursor: not-allowed;
      &::placeholder {
        color: ${`rgba(${hexToRgb(COLORS.SECONDARY_500)}, 0.3)`};
      }
    }

    &:hover, &:active {
      border-color: ${props.error ? COLORS.DANGER_500 : COLORS.SECONDARY_700};
    }

    &:not([disabled])::placeholder {
      font-size: ${TYPOGRAPHY.P};
      font-family: ${TYPOGRAPHY.SANS_SERIF};
      color: ${props.error ? COLORS.DANGER_500 : COLORS.SECONDARY_500};
    }
  `}
`;

export const InnerIconRight = styled(FormElementIcon)`
  ${(props: FormElementProps) => `
    top: 0;
    z-index: 1;
    right: 0;
    opacity: ${props.disabled ? 0.5 : 1};
    svg {
      color: ${props.error ? COLORS.DANGER_500 : 'inherit'};
    }
  `};
`;

export const InnerIconLeft = styled.div`
  ${(props: FormElementProps) => `
    top: 0;
    z-index: 1;
    left: 0;
    opacity: ${props.disabled ? 0.5 : 1};
    ${
      props.error
        ? `svg {
        color: ${COLORS.DANGER_500};
      }`
        : null
    }
  `}
`;

export const PrependWrapper = styled.div`
  ${(props: FormElementProps) => `
  border-right-style: solid;
    border-right-width: ${props.disabled && props.fieldStyle === 'input' ? 0 : '1px'};
    border-right-color: ${props.error ? COLORS.DANGER_300 : COLORS.SECONDARY_300};
    border-top-left-radius: ${RADIUSES.sm};
    border-bottom-left-radius: ${RADIUSES.sm};
    max-width: 50%;
    flex-direction: column;
    display: flex;
  `};
`;

export const IndicatorWrapper = styled.div`
  min-width: 0.875rem;
`;

export const getSelectStyles = (props: SelectProps): StylesConfig<unknown, boolean> => {
  return {
    control: (provided, state) => ({
      ...provided,
      border: 'none',
      boxShadow: 'none',
      cursor: state.isDisabled ? 'not-allowed' : 'pointer',
      caretColor: 'transparent',
      borderTopRightRadius: '0.375rem',
      borderBottomRightRadius: '0.375rem',
      borderTopLeftRadius: props.prependComponent ? 0 : '0.375rem',
      borderBottomLeftRadius: props.prependComponent ? 0 : '0.375rem',
      backgroundColor: props.error ? COLORS.DANGER_100 : COLORS.NEUTRAL_100,
      '&:hover': {
        boxShadow: 'none',
      },
    }),
    placeholder: (provided, state) => ({
      ...provided,
      left: 0,
      right: 0,
      margin: 0,
      width: '100%',
      overflow: 'hidden',
      whiteSpace: 'nowrap',
      pointerEvents: 'auto',
      textOverflow: 'ellipsis',
      fontFamily: TYPOGRAPHY.SANS_SERIF,
      opacity: state.isDisabled ? 0.6 : 1,
      padding: props.innerIconLeft ? '2px 8px 2px 32px' : '2px 8px',
      color: props.error ? COLORS.DANGER_500 : state.isDisabled ? COLORS.SECONDARY_500 : COLORS.SECONDARY_900,
    }),
    valueContainer: (provided) => ({
      ...provided,
      padding: props.innerIconLeft ? '2px 8px 2px 32px' : '2px 8px',
    }),
    multiValue: (provided) => ({
      ...provided,
      borderRadius: RADIUSES.sm,
      color: COLORS.SECONDARY_900,
      backgroundColor: COLORS.SECONDARY_300,
      '&:hover': {
        color: COLORS.PRIMARY_500,
        backgroundColor: COLORS.PRIMARY_200,
      },
    }),
    multiValueLabel: (provided) => ({
      ...provided,
      borderRadius: 0,
      color: 'inherit',
      padding: '0.25rem',
      paddingLeft: '0.5rem',
      fontSize: TYPOGRAPHY.SMALL,
      fontFamily: TYPOGRAPHY.SANS_SERIF,
    }),
    indicatorsContainer: (provided, state) => ({
      ...provided,
      minHeight: '2.375rem',
      alignSelf: 'start',
      padding: props.tipIcon || props.errorIcon ? '0 1.875rem' : '0 0.5rem',
      color: props.error ? COLORS.DANGER_500 : state.isDisabled ? COLORS.SECONDARY_300 : COLORS.SECONDARY_700,
    }),
    container: (provided) => ({
      ...provided,
      border: 'none',
      flex: 1,
      borderColor: 'transparent',
    }),
    menu: (provided) => ({
      ...provided,
      border: 'none',
      padding: '0.5rem 0',
      zIndex: 2,
      borderRadius: '0.375rem',
      boxShadow: SHADOWS.DEPTH_5.SECONDARY_900,
      maxWidth: typeof props.maxMenuWidth === 'string' ? props.maxMenuWidth : `${props.maxMenuWidth}px`,
    }),
    menuList: (provided) => ({
      ...provided,
    }),
    option: (provided, state) => ({
      ...provided,
      cursor: 'pointer',
      fontFamily: TYPOGRAPHY.SANS_SERIF,
      color: state.isFocused ? COLORS.PRIMARY_500 : COLORS.SECONDARY_900,
      backgroundColor: state.isFocused ? COLORS.PRIMARY_100 : COLORS.NEUTRAL_100,
      '&:active': {
        backgroundColor: COLORS.PRIMARY_200,
      },
    }),
    singleValue: (provided, state) => ({
      ...provided,
      opacity: state.isDisabled ? 0.5 : 1,
      color: props.error ? COLORS.DANGER_500 : COLORS.SECONDARY_900,
      fontFamily: TYPOGRAPHY.SANS_SERIF,
    }),
  };
};

import { cx } from '@emotion/css';
import { ComponentPropsWithoutRef, FC, ReactElement, useEffect, useState } from 'react';

import type { Size } from '../../types';

type Props = {
  className?: string;
  counter?: number;
  disabled?: boolean;
  error?: boolean;
  errorIcon?: boolean;
  errorMessage?: string;
  labelIcon?: ReactElement;
  label?: ReactElement | string;
  maxLength?: number;
  size?: Size | '';
  tip?: boolean;
  tipMessage?: string;
  dataTestId?: string;
} & Partial<ComponentPropsWithoutRef<'input'>>;

export const FormElementWrapper: FC<Props> = (props) => {
  const {
    children,
    className,
    counter,
    dataTestId,
    disabled,
    error,
    errorIcon,
    errorMessage,
    labelIcon,
    label,
    maxLength,
    size,
    tipMessage,
    ...restProps
  } = props;

  const [isErrorVisible, setErrorVisible] = useState(false);

  useEffect(() => {
    if (error && !errorIcon && !!errorMessage && !disabled) {
      setErrorVisible(true);
    }
  }, [error, errorIcon, errorMessage, disabled]);

  return (
    <div data-testid={dataTestId} className={cx(className, 'layout-column layout-align-start-stretch width-100')}>
      {label && (
        <label data-testid="form-label" className="small medium mb-4 display-block" htmlFor={restProps.id}>
          {label}
        </label>
      )}

      <div className="relative layout-row layout-align-start-start width-100">
        {labelIcon && (
          <div
            data-testid="form-icon"
            className={cx({ 'text-danger-500': error }, 'layout-column layout-align-center-center pt-12 pr-8')}>
            {labelIcon}
          </div>
        )}
        <div className={'layout-align-start-stretch layout-column flex'}>
          <div className="layout-column layout-align-start-start width-100">{children}</div>
          {(isErrorVisible || maxLength || tipMessage) && (
            <div className="layout-row layout-align-start-start pt-4">
              <div className={cx({ 'pr-16': !!maxLength }, 'flex layout-row layout-align-start-start')}>
                {isErrorVisible && (
                  <p data-testid="form-error-message" className="small text-error">
                    {errorMessage}
                  </p>
                )}
                {tipMessage && !error && (
                  <p data-testid="form-tip-message" className="text-secondary-500 small">
                    {tipMessage}
                  </p>
                )}
              </div>
              {maxLength && (
                <div data-testid="form-length">
                  <small className={cx(isErrorVisible ? 'text-danger-500' : 'text-secondary-500')}>
                    {counter}/{maxLength}
                  </small>
                </div>
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

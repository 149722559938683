import { cx } from '@emotion/css';
import { InfoTwoTone, WarningTwoTone } from '@mui/icons-material';
import type { FC, HTMLAttributes, ReactNode } from 'react';

import { Tooltip } from '../../Tooltip';

type FormElementIconProps = {
  className?: string;
  error?: boolean;
  title?: NonNullable<ReactNode>;
} & HTMLAttributes<HTMLDivElement>;

export const FormElementIcon: FC<FormElementIconProps> = (props) => {
  const { className, error, title, ...restProps } = props;
  return (
    <div className={cx(className, 'layout-column height-100 layout-align-start-center p-8')} {...restProps}>
      {title ? (
        <Tooltip arrow title={title}>
          {error ? (
            <WarningTwoTone style={{ fontSize: 14 }} className="text-danger-500" />
          ) : (
            <InfoTwoTone style={{ fontSize: 14 }} className="text-secondary-700" />
          )}
        </Tooltip>
      ) : error ? (
        <WarningTwoTone style={{ fontSize: 14 }} className="text-danger-500" />
      ) : (
        <InfoTwoTone style={{ fontSize: 14 }} className="text-secondary-700" />
      )}
    </div>
  );
};

import { CloseRounded } from '@mui/icons-material';

import * as S from '../styles';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const CustomRemoveIndicator = ({ innerRef, innerProps }: any) => (
  <S.IndicatorWrapper ref={innerRef} {...innerProps} className="layout-column layout-align-center-center mx-8">
    <CloseRounded style={{ fontSize: 14 }} />
  </S.IndicatorWrapper>
);

'use client';

import { cx } from '@emotion/css';
import { css, Global } from '@emotion/react';
import type { TooltipProps as TooltipMuiProps } from '@mui/material';
import { default as MuiTooltip } from '@mui/material/Tooltip';
import { FC, Fragment, ReactElement, ReactNode } from 'react';

import { COLORS, RADIUSES, TYPOGRAPHY } from '../../variables';

export type TooltipProps = {
  className?: string;
  arrow?: boolean;
  error?: boolean;
  children: ReactElement;
  title: NonNullable<ReactNode>;
  disabled?: boolean;
  maxWidth?: 'none' | `${number}px` | `${number}`;
  placement?:
    | 'bottom-end'
    | 'bottom-start'
    | 'bottom'
    | 'left-end'
    | 'left-start'
    | 'left'
    | 'right-end'
    | 'right-start'
    | 'right'
    | 'top-end'
    | 'top-start'
    | 'top';
} & TooltipMuiProps;

export const Tooltip: FC<TooltipProps> = (props) => {
  const { title, children, placement, arrow, error, maxWidth, disabled, ...restProps } = {
    ...props,
  };

  if (disabled) {
    return children;
  }

  return (
    <Fragment>
      <Global
        styles={css`
          .styled-tooltip {
            border-radius: ${RADIUSES.md} !important;
            font-size: 0.875rem !important;
            padding: 0.75rem !important;
            font-weight: normal !important;
            font-family: ${TYPOGRAPHY.SANS_SERIF} !important;
            max-width: ${maxWidth} !important;
          }
          .styled-tooltip:not(.tooltip-error) {
            color: ${COLORS.SECONDARY_100} !important;
            background-color: ${COLORS.SECONDARY_900} !important;
          }
          .styled-tooltip.tooltip-error {
            color: ${COLORS.DANGER_900} !important;
            background-color: ${COLORS.DANGER_300} !important;
          }
          .styled-tooltip-arrow:not(.tooltip-error) {
            color: ${COLORS.SECONDARY_900} !important;
          }
          .styled-tooltip-arrow.tooltip-error {
            color: ${COLORS.DANGER_300} !important;
          }
          .css-1d3ml05-MuiPopper-root-MuiTooltip-popper[data-popper-placement*='right'] .MuiTooltip-arrow::before {
            border-bottom-left-radius: 2.5px;
          }
          .css-1d3ml05-MuiPopper-root-MuiTooltip-popper[data-popper-placement*='left'] .MuiTooltip-arrow::before {
            border-top-right-radius: 2.5px;
          }
          .css-1d3ml05-MuiPopper-root-MuiTooltip-popper[data-popper-placement*='bottom'] .MuiTooltip-arrow::before {
            border-top-left-radius: 2.5px;
          }
          .css-1d3ml05-MuiPopper-root-MuiTooltip-popper[data-popper-placement*='top'] .MuiTooltip-arrow::before {
            border-bottom-right-radius: 2.5px;
          }
        `}
      />
      <MuiTooltip
        title={title}
        arrow={arrow}
        placement={placement}
        classes={{
          tooltip: cx({ 'tooltip-error': error }, 'styled-tooltip'),
          arrow: cx({ 'tooltip-error ': error }, 'styled-tooltip-arrow'),
        }}
        {...restProps}>
        {children}
      </MuiTooltip>
    </Fragment>
  );
};

import { cx } from '@emotion/css';
import { ArrowDropDownRounded, CalendarTodayTwoTone } from '@mui/icons-material';

import * as S from '../styles';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const CustomDateIndicator = ({ innerRef, innerProps, error }: any) => {
  return (
    <S.IndicatorWrapper className="layout-column layout-align-center-center">
      <CalendarTodayTwoTone
        ref={innerRef}
        {...innerProps}
        className={cx({ 'text-danger-500': error })}
        style={{ fontSize: 16 }}
      />
    </S.IndicatorWrapper>
  );
};

export const CustomDropdownIndicator = ({
  innerRef,
  innerProps,
  error,
}: // eslint-disable-next-line @typescript-eslint/no-explicit-any
any) => {
  return (
    <S.IndicatorWrapper className="layout-column layout-align-center-center">
      <ArrowDropDownRounded
        ref={innerRef}
        {...innerProps}
        className={cx({ 'text-danger-500': error })}
        style={{ fontSize: 24 }}
      />
    </S.IndicatorWrapper>
  );
};

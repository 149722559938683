'use client';

import { cx } from '@emotion/css';
import type { FC } from 'react';

import type { Size } from '../types';
import * as S from './styles';

type LoaderProps = {
  size?: Size;
  color?: string;
  message?: string;
  dots?: boolean;
  className?: string;
  messageClassName?: string;
  isPageLoader?: boolean;
};

const defaultProps: LoaderProps = {
  size: 'sm',
  dots: true,
  messageClassName: 'small text-secondary-700',
  color: 'primary-500',
  isPageLoader: true,
};

const mapPropsToSize = (size: Size = 'md'): number => {
  switch (size) {
    case 'xs':
      return 4;
    case 'sm':
      return 8;
    case 'lg':
      return 24;
    case 'md':
    default:
      return 16;
  }
};

/*
 * TEMPORARY:HACK: adding ({...}: Props) as a temporary fix for
 * https://github.com/storybookjs/storybook/blob/2d540b988ee665f793d0ef9860b732087c817193/MIGRATION.md#reactfc-interfaces
 * until the issue is resolved
 */
export const Loader: FC<LoaderProps> = (props: LoaderProps) => {
  const { color, size, isPageLoader, message, dots, className, messageClassName } = { ...defaultProps, ...props };
  const radius = mapPropsToSize(size || 'sm');

  return (
    <S.Loader
      data-testid="loader"
      className={cx(
        className,
        {
          'page-loader layout-column layout-align-center-center absolute bg-secondary-100 height-100 width-100':
            isPageLoader,
        },
        'loader',
      )}>
      <div className="layout-column layout-align-center-center">
        <svg style={{ width: radius * 4, height: radius * 4 }}>
          <circle
            cx={radius * 2}
            cy={radius * 2}
            r={radius}
            fill="none"
            strokeWidth={radius / 4}
            strokeMiterlimit="10"
            className={`stroke-${color}`}
          />
        </svg>
        {message ? <span className={cx(messageClassName, { dots: dots }, 'layout-row')}>{message}</span> : null}
      </div>
    </S.Loader>
  );
};
